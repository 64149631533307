

export const asciiArt = [
    (
        <pre>
        ------ <br />
        | 0 0 |  <br />
        |  _  |  <br />
        ------ <br />
        /      \ <br />
        |       | <br />
    </pre>),
    (
    <pre>
        ------ <br />
        | 0 0 |  <br />
        |  _  |  <br />
        ------ <br />
      _/      \_ <br />
     / |       | \ <br />
    </pre>),
    (
    <pre>
        ------ <br />
        | 0 0 |  <br />
        |  _  |  <br />
        ------ <br />
    \_ /      \_/ <br />
        |       | <br />
    </pre>),

]

export const asciiArtBig = (<pre>
                                                                                                        
                                                                                          <br />                                                                                                      
                                    &nbsp; @=<br />         
                                    @@*           @                                        <br />         
                                   @@@:  @  -*@@@@@                                        <br />         
                                   @@@@.@@ @@@@@@@ %@@. :: @@@                             <br />         
                                 @@@@=@@@  @@@+@@@@@@@@  @@@@# .                           <br />         
                              .@@@@:  @@  #%@ .@@@@@@@@@ :@  =                             <br />         
                             @@@@   =@%    :.  -@@@@@   .                                  <br />         
                          .@@@ @   .@@:@.  @@  @@@@@    :                                  <br />         
                           :@    *@@@@@@@  @@# @@@+   @@=     .                            <br />         
                           *@ .@@@@@@@@@@@ *@@ :@@+  .-              @%                    <br />         
                          .@@@@@@@@@@@@@@@  @@ #%@   *@@*           :@@                    <br />         
                         %@@@@@@@@@  @@@@@@#@*  .    %@@@@@*  =@@#   .@:                   <br />         
                        -@@@@@@@@@@  @@@@@@@@- @@@   -@@@     @@@@                         <br />         
                        @@@@@@@@@@@ @@@@@@@@@ *    .@@@@      @@@@                         <br />         
                       -@@@@@@@@@@@@@@@@@@@:   .#.   %@@       @@@#@                       <br />         
                       @@@@@@@@@@@@@.@@@ *@  .@#-    -@        .@@@@@                      <br />         
                       @@@@@@@@@%@@  @@  @@  #@@@@@+@@:          @@% %@:   @               <br />         
                       @  @@-@@ : @@@@@  @@@ @@@@@@@@            @@.  :@@ :@@=             <br />         
                              =          :-% %@@@               *@@@    @@@@@              <br />         
                        .                                      @@@@@    #@@*               <br />         
                       @@@   @@@                              @@@*      =@@.               <br />         
                       .%. .@@@@                            @@@          :#                <br />         
                           +  @@           -@                                              <br />         
                                        @* @@@@@             @@                            <br />         
                                       .@@@@@@@@@ :         :@                             <br />         
                                       @@@@@@@@@@@@@@@                                     <br />         
                                      @@@@@@@@@@@@@@@@.                                    <br />         
                                   @@@@@@@@@@@@@@@@@@@@                                    <br />         
                                   %@@@@@@@@@@@@@@@@@                                      <br />         
                                 @ .@@@@@@@@@@@@@@@#    %@%       .=                       <br />         
                                :@@@@@@#*@@@@@@@@@@  =@@@@                                 <br />         
                                 .@@@@@@+%@@@@@@@@@@@@@@-                 *@               <br />         
                       :@@@@@@@%#:@@@@@@@-  .@.#@@@@@@@    :@.   @@   #  @+                <br />         
                            .%@@@@@@@@@           .%@.   @@@.  .@@@@@   @%          =      <br />         
                  @   @@@@@@%      -*       @@-@@-      :@@  %@@@@@@   #@     -  -         <br />         
                 @@   #@@@@@@#@@@@ :      @@@@@@@@@@    @@  @@@@@@@   @@       -           <br />         
             :  #@@%@ #@@@@@* #.        =@@@@@@@@@@@ @@@@ *@@@@@@-   @@         =          <br />         
          @= @+ .@@@@@@@@@@@@@@@@@@@@% @@@@@@@@@@@@@@@@  *@=@@@=   @@%                     <br />         
        .@@@ #@@:@@@@@@@@@@@@@@@@@@ .  :@@@@@@@@@@@@@@@   =+ @@   @@     .                 <br />         
         @@@. @@@@@@@@@@@@@@@@@@@@.   @@@@@@@@@@@@@@@@@@@@@@@@@%@@@@ *@@@@@@@     <br />
         </pre>      
);